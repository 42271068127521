    
// Hamburger
const hamburgerIcon = document.querySelector(".nav--hamburger__hamburger-icon");
const mobileNavContent = document.querySelector(".nav--hamburger--center");

hamburgerIcon.addEventListener("click", function(event){
  hamburgerIcon.classList.toggle("open");
  mobileNavContent.classList.toggle("open");
});


// Scroll sticky nav
// https://webdesign.tutsplus.com/tutorials/how-to-hide-reveal-a-sticky-header-on-scroll-with-javascript--cms-33756

const body = document.body;
const nav = document.querySelector(".hide-on-medium-down");
const CLASS_SCROLL_UP = "scroll-up";
const CLASS_SCROLL_DOWN = "scroll-down";
const NAV_EFFECT_TOP_OFFSET = 90;
let lastScroll = 0;
 
window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= NAV_EFFECT_TOP_OFFSET) {
    nav.classList.remove(CLASS_SCROLL_UP);
    return;
  }

   
  if (currentScroll > lastScroll && !nav.classList.contains(CLASS_SCROLL_DOWN)) {
    // down
    nav.classList.remove(CLASS_SCROLL_UP);
    nav.classList.add(CLASS_SCROLL_DOWN);
  } else if (currentScroll < lastScroll && nav.classList.contains(CLASS_SCROLL_DOWN)) {
    // up
    nav.classList.remove(CLASS_SCROLL_DOWN);
    nav.classList.add(CLASS_SCROLL_UP);
  }
  lastScroll = currentScroll;
});